import { styles } from "./styles";
import { ChangeEvent, KeyboardEvent, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Backdrop,
  CircularProgress,
  Button,
  InputAdornment,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useForm, Controller } from "react-hook-form";
import { SearchResult } from "./SearchInterfaces";
import { SearchInput, SearchProps } from "./SearchInterfaces";
import SearchResultList from "./SearchResultList";
import useSearchService from "../../services/SearchService";
import { AlertMessage } from "../Home/HomeInterfaces";

interface SearchTextMap {
  rolv: string;
  iso: string;
  macroLanguage: string;
  individualLanguage: string;
  productCode: string;
  bible: string;
  fcbhLanguageName: string;
  fcbhAltLanguageName: string;
  [key: string]: string;
}

const Search = ({
  selectedLanguage,
  setSelectedLanguage,
  setAlertList,
  enableAutoScrolling,
  handleAutoScrollingChange,
}: SearchProps) => {
  const { get } = useSearchService();
  const {
    control,
    formState: { errors, isValid },
    setValue,
    getValues,
    trigger,
    clearErrors,
  } = useForm<SearchInput>({
    defaultValues: {
      searchInput: "",
    },
  });

  const [searchInput, setSearchInput] = useState<string>(
    getValues("searchInput"),
  );

  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isFetched, setIsFetched] = useState<boolean>(false);

  // default sort is by ISO, then by Name
  const sortResults = (results: SearchResult[]) => {
    return [...results].sort((a: SearchResult, b: SearchResult) => {
      if (a.iso > b.iso) {
        return 1;
      } else if (a.iso < b.iso) {
        return -1;
      } else {
        if (a.fcbhName > b.fcbhName) {
          return 1;
        } else if (a.fcbhName < b.fcbhName) {
          return -1;
        } else {
          return 0;
        }
      }
    });
  };

  const search = (): void => {
    setIsFetching(true);

    get({ query: searchInput })
      .then((results) => {
        if (results) {
          setSearchResults(sortResults(results));

          if (results.length === 1) {
            setSelectedLanguage(results[0]);
          } else {
            setSelectedLanguage(undefined);
          }
        }
      })
      .catch((error: unknown) => {
        console.error(error);
        setSelectedLanguage(undefined);
        setAlertList((prev: AlertMessage[]) => {
          return [
            ...prev,
            {
              message:
                "There was an error retrieving search results — try again!",
              key: new Date().getTime(),
            },
          ];
        });
      })
      .finally(() => {
        setIsFetched(true);
        setIsFetching(false);
      });
  };

  const handleSearchChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): string => {
    setValue("searchInput", event.target.value);
    setSearchInput(event.target.value);

    if (!isValid) {
      clearErrors("searchInput");
    }
    return event.target.value;
  };

  const handleEnterKeypress = () => {
    if (
      getValues("searchInput").trim() &&
      getValues("searchInput").length >= 2
    ) {
      setSearchResults([]);
      search();
    }
  };

  const getSearchTypeLabel = (): string => {
    if (isFetched && searchResults && searchResults.length > 0) {
      const searchTextMap: SearchTextMap = {
        rolv: "Search matched on rolv.",
        iso: "Search matched on iso.",
        macroLanguage: "Search matched on macro language.",
        individualLanguage: "Search matched on individual language.",
        productCode: "Search matched on product code.",
        bible: "Search matched on bible.",
        fcbhLanguageName: "Search matched on FCBH language name.",
        fcbhAltLanguageName: "Search matched on FCBH alternate language name.",
      };

      return searchTextMap[searchResults[0].resultType];
    }

    return "";
  };

  return (
    <Box>
      <Box sx={styles.container}>
        <Backdrop sx={styles.backdrop} open={isFetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item xs={12}>
          <Typography variant="h3" sx={styles.sectionTitle}>
            Search
          </Typography>
        </Grid>
        <Box component="form">
          <Grid container direction="row" spacing={2} alignItems="flex-start">
            <Grid item xs={4}>
              <Controller
                name={"searchInput"}
                control={control}
                rules={{
                  minLength: {
                    value: 2,
                    message: `Min length is 2`,
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Type your search"
                    size="medium"
                    onChange={handleSearchChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" color="primary">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onKeyPress={(event: KeyboardEvent<HTMLDivElement>) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        trigger("searchInput");
                        handleEnterKeypress();
                      }
                    }}
                    error={errors.searchInput?.type !== undefined}
                    helperText={
                      errors.searchInput?.type !== undefined
                        ? errors.searchInput?.message
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={1.5}>
              <Button
                variant="contained"
                disableElevation
                sx={{ marginTop: "10px" }}
                onClick={() => {
                  search();
                }}
              >
                Search
              </Button>
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableAutoScrolling}
                    onChange={handleAutoScrollingChange}
                  />
                }
                label="Auto-Scrolling"
              />
            </Grid>
            <Grid item xs={12} sx={{ opacity: ".6" }}>
              <Typography variant="subtitle1">
                {getSearchTypeLabel()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {isFetched && searchResults.length === 0 ? (
                <Typography variant="subtitle1">No search results.</Typography>
              ) : (
                <SearchResultList
                  results={searchResults}
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Search;
