import { CustomUserClaim } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState, useMemo } from "react";
import {
  RESTRICTED_READ_ONLY,
  LANGUAGE_ADMIN,
  PERMISSION_ADMIN,
} from "./authorizationConstants";

export interface UserContextType {
  claimGroups: CustomUserClaim[];
  canAdminLanguages: boolean;
  canAdminBibles: boolean;
  canAdminMedia: boolean;
  canAdminPermissions: boolean;
  canRestrictedReadOnly: boolean;
}

export const useUserInfo = (): UserContextType => {
  const { oktaAuth, authState } = useOktaAuth() || {};
  const [claimGroups, setClaimGroups] = useState<CustomUserClaim[] | null>(
    null,
  );

  useEffect(() => {
    const fetchUserClaims = async () => {
      if (authState?.isAuthenticated && authState?.accessToken) {
        const accessToken = authState.accessToken;
        if (accessToken) {
          const claims = accessToken.claims;
          setClaimGroups((claims?.groups as CustomUserClaim[]) || []);
        } else {
          setClaimGroups([]);
        }
      } else {
        setClaimGroups(null);
      }
    };

    fetchUserClaims();
  }, [authState, oktaAuth]);

  // Okta groups:
  // - LanguageAdmin
  // - PermissionAdmin
  return useMemo(
    () => ({
      claimGroups: claimGroups ?? [],
      canAdminLanguages: claimGroups?.includes(LANGUAGE_ADMIN) ?? false,
      canAdminBibles: claimGroups?.includes(LANGUAGE_ADMIN) ?? false,
      canAdminMedia:
        claimGroups?.includes(LANGUAGE_ADMIN) ??
        claimGroups?.includes(PERMISSION_ADMIN) ??
        false,
      canAdminPermissions: claimGroups?.includes(PERMISSION_ADMIN) ?? false,
      canRestrictedReadOnly:
        claimGroups?.includes(RESTRICTED_READ_ONLY) ?? false,
    }),
    [claimGroups],
  );
};
