import { useMemo } from "react";
import { useUserInfo } from "../provider/UserProvider";
import {
  GUEST_USER,
  RESTRICTED_READ_ONLY,
  LANGUAGE_ADMIN,
  PERMISSION_ADMIN,
} from "./authorizationConstants";

export const useUserAuthorization = () => {
  const { canAdminLanguages, canAdminPermissions, canRestrictedReadOnly } =
    useUserInfo();

  const userType = useMemo((): string => {
    if (canAdminLanguages) {
      return LANGUAGE_ADMIN;
    }

    if (canAdminPermissions) {
      return PERMISSION_ADMIN;
    }

    if (canRestrictedReadOnly) {
      return RESTRICTED_READ_ONLY;
    }

    return GUEST_USER;
  }, [canAdminLanguages, canAdminPermissions, canRestrictedReadOnly]);

  return {
    userType,
  };
};
