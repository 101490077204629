import React, { useState, useEffect } from "react";
import {
  Grid2,
  TextField,
  IconButton,
  Stack,
  Tooltip,
  Checkbox,
  Backdrop,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import { Check, Clear } from "@mui/icons-material";
import { RefetchOptions, QueryObserverResult } from "@tanstack/react-query";
import { styles } from "./styles";
import { Permission as PermissionInterface } from "./PermissionInterfaces";
import { MODE_ID_AUDIO, MODE_ID_VIDEO, MODE_ID_TEXT } from "./config";
import {
  extractCopyrightInfo,
  addCopyrightCombined,
  removeFirstAndConcatenate,
  modeTypeNameFromLicenseName,
  getTextCopyrightKey,
} from "./PermissionUtils";
import { handleCombineCopyright } from "./PermissionHandlers";
import usePermissionService from "../../services/PermissionService";

interface CopyrightEditorProps {
  permission: PermissionInterface | undefined;
  mediaModeId: number;
  disabled: boolean;
  setPermission: React.Dispatch<
    React.SetStateAction<PermissionInterface | undefined>
  >;
  showMessage: (message: string, type?: string) => void;
  getPermission: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<PermissionInterface, Error>>;
  licenseGroupCopyrights: Record<string, string>;
}

const CopyrightEditor = ({
  permission,
  mediaModeId,
  disabled,
  showMessage,
  getPermission,
  licenseGroupCopyrights,
}: CopyrightEditorProps) => {
  const [isCombineCopyrightEnable, setIsCombineCopyrightEnable] =
    useState<boolean>(true);
  const [copyrightValue, setCopyrightValue] = useState<string>("");
  const [permissionCopyright, setPermissionCopyright] = useState<string>("");
  const [editingCopyright, setEditingCopyright] = useState<boolean>(false);
  const [isSavingCopyright, setIsSavingCopyright] = useState<boolean>(false);

  const { updateLicenseGroup } = usePermissionService();

  const saveCopyright = async () => {
    if (permission) {
      setIsSavingCopyright(true);
      try {
        await updateLicenseGroup({
          ...permission,
          copyright: permissionCopyright,
        });

        await getPermission();
        showMessage("Copyright saved.", "success");
        setEditingCopyright(false);
        setIsSavingCopyright(false);
      } catch (error: unknown) {
        showMessage("Error saving copyright.");
        setEditingCopyright(false);
        setIsSavingCopyright(false);
        console.log(error);
      }
    }
  };

  const cancelEdits = () => {
    setEditingCopyright(false);
  };

  useEffect(() => {
    if (mediaModeId !== MODE_ID_TEXT) {
      setIsCombineCopyrightEnable(true);
    } else {
      setIsCombineCopyrightEnable(false);
    }
  }, [mediaModeId]);

  useEffect(() => {
    if (permission?.copyright) {
      const extractedInfo = extractCopyrightInfo(
        permission?.copyright,
        mediaModeId,
      );
      if (extractedInfo === null) {
        // If it is null, it means that the copyright is not combined otherwise, it is a combined copyright
        setIsCombineCopyrightEnable(false);
      } else {
        setIsCombineCopyrightEnable(true);
      }
      setCopyrightValue(extractedInfo ?? permission?.copyright);
    } else {
      setCopyrightValue("");
      setIsCombineCopyrightEnable(false);
    }
  }, [permission, mediaModeId]);

  useEffect(() => {
    const handleAudioMode = (textCopyrightKey: string) => {
      let textCopyright = licenseGroupCopyrights?.[textCopyrightKey] ?? "";

      // Cover the case where the text copyright do not exist, but the audio copyright has a complete copyright text.
      if (!textCopyright && permission?.copyright) {
        const textCopyrightExtracted = extractCopyrightInfo(
          permission?.copyright,
          MODE_ID_TEXT,
        );
        textCopyright = `Text: ${textCopyrightExtracted ?? ""}`;
      } else {
        textCopyright = `Text: ${textCopyright ?? ""}`;
      }

      setPermissionCopyright(
        addCopyrightCombined(MODE_ID_AUDIO, textCopyright, copyrightValue),
      );
    };

    const handleVideoMode = (
      copyrightModeKey: string,
      textCopyrightKey: string,
      permissionModeTypeName: string,
    ) => {
      const audioCopyrightKey = copyrightModeKey.replace(
        permissionModeTypeName,
        "audio",
      );
      let audioCopyright =
        licenseGroupCopyrights?.[audioCopyrightKey] ??
        licenseGroupCopyrights?.[
          `${copyrightModeKey.replace(permissionModeTypeName, "audio-drama")}`
        ];

      const textCopyright = licenseGroupCopyrights?.[textCopyrightKey] ?? "";

      // Cover the case where the audio and text copyrights do not exist, but the video copyright has a complete copyright text.
      if (!textCopyright && !audioCopyright && permission?.copyright) {
        const audioCopyrightExtracted = extractCopyrightInfo(
          permission?.copyright,
          MODE_ID_AUDIO,
        );
        const textCopyrightExtracted = extractCopyrightInfo(
          permission?.copyright,
          MODE_ID_TEXT,
        );

        audioCopyright = `Text: ${textCopyrightExtracted ?? ""}\nAudio: ${audioCopyrightExtracted ?? ""}`;
      } else if (textCopyright && !audioCopyright && permission?.copyright) {
        const audioCopyrightExtracted = extractCopyrightInfo(
          permission?.copyright,
          MODE_ID_AUDIO,
        );

        audioCopyright = `Text: ${textCopyright}\nAudio: ${audioCopyrightExtracted ?? ""}`;
      } else if (!textCopyright && audioCopyright && permission?.copyright) {
        const textCopyrightExtracted = extractCopyrightInfo(
          permission?.copyright,
          MODE_ID_TEXT,
        );

        audioCopyright = `Text: ${textCopyrightExtracted ?? ""}\nAudio: ${audioCopyright ?? ""}`;
      } else {
        // Cover the case where the audio copyright exists but has not been combined
        // and therefore does not have the pattern "Audio:"
        const audioCopyrightExtracted = extractCopyrightInfo(
          audioCopyright,
          MODE_ID_AUDIO,
        );
        const textCopyrightExtracted = extractCopyrightInfo(
          audioCopyright,
          MODE_ID_TEXT,
        );
        const videoCopyrightExtracted = extractCopyrightInfo(
          audioCopyright,
          MODE_ID_VIDEO,
        );

        if (
          !audioCopyrightExtracted &&
          !textCopyrightExtracted &&
          !videoCopyrightExtracted
        ) {
          audioCopyright = `Text: ${textCopyright ?? ""}\nAudio: ${audioCopyright}`;
        }
      }

      setPermissionCopyright(
        addCopyrightCombined(MODE_ID_VIDEO, audioCopyright, copyrightValue),
      );
    };

    if (isCombineCopyrightEnable) {
      const copyrightModeKey = permission?.name
        ? removeFirstAndConcatenate(permission.name)
        : "";
      const permissionModeTypeName = permission?.name
        ? modeTypeNameFromLicenseName(permission.name)
        : "";
      const textCopyrightKey = getTextCopyrightKey(
        copyrightModeKey,
        permissionModeTypeName,
      );

      if (mediaModeId === MODE_ID_AUDIO) {
        handleAudioMode(textCopyrightKey);
      }

      if (mediaModeId === MODE_ID_VIDEO) {
        handleVideoMode(
          copyrightModeKey,
          textCopyrightKey,
          permissionModeTypeName,
        );
      }
    } else {
      setPermissionCopyright(copyrightValue);
    }
  }, [
    copyrightValue,
    isCombineCopyrightEnable,
    licenseGroupCopyrights,
    mediaModeId,
    permission,
  ]);

  const handleCopyrightInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    const value = event.target.value;
    setEditingCopyright(true);
    setCopyrightValue(value);
  };

  return (
    <>
      <Backdrop sx={styles.backdrop} open={isSavingCopyright}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid2 size={12}>
        <TextField
          label="Copyright"
          value={copyrightValue}
          onChange={handleCopyrightInputChange}
          disabled={disabled}
          name="copyright"
          multiline={true}
          rows={5}
          fullWidth={true}
          slotProps={{
            input: {
              className: "copyrightInput",
            },
          }}
        />
        {mediaModeId !== MODE_ID_TEXT && (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isCombineCopyrightEnable}
                  onChange={handleCombineCopyright(
                    setIsCombineCopyrightEnable,
                    setEditingCopyright,
                  )}
                  name="combine"
                  disabled={disabled}
                />
              }
              label="Combine"
            />
            {isCombineCopyrightEnable && (
              <TextField
                label="Combine Copyright"
                value={permissionCopyright ?? ""}
                disabled={true}
                name="combineCopyright"
                multiline={true}
                rows={5}
                fullWidth={true}
                slotProps={{
                  input: {
                    className: "copyrightInput",
                  },
                }}
              />
            )}
          </>
        )}
      </Grid2>
      <Grid2
        size={12}
        style={{
          display: "flex",
          justifyContent: "right",
          gap: "10px",
          padding: "20px 0",
        }}
      >
        {!disabled && editingCopyright && (
          <Stack direction="row" spacing={0}>
            <Tooltip title="Save Changes">
              <IconButton onClick={saveCopyright} size="large">
                <Check color="success" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel Changes">
              <IconButton onClick={cancelEdits} size="large">
                <Clear color="primary" />
              </IconButton>
            </Tooltip>
          </Stack>
        )}
      </Grid2>
    </>
  );
};

export default React.memo(CopyrightEditor);
