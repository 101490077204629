import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useMemo,
} from "react";
import { isAxiosError } from "axios";
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
  IconButton,
  Stack,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  Divider,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Check, Clear, LinkOff } from "@mui/icons-material";
import {
  PermissionProps,
  Permission as PermissionInterface,
  Licensor,
  PermissionOption,
  PermissionPattern,
  NewPermissionPattern,
  ConfirmationDialog,
  PERMISSION_CUSTOM_ID,
  PERMISSION_CUSTOM_NAME,
} from "./PermissionInterfaces";
import PermissionTableContainer from "./PermissionTableContainer";
import ManageLicensorsDialog from "./ManageLicensorsDialog";
import CopyrightEditor from "./CopyrightEditor";
import { styles } from "./styles";
import { deepPermissionCopy, arePermissionsEqualById } from "./PermissionUtils";
import { AlertMessage } from "../Home/HomeInterfaces";
import usePermissionService, {
  PermissionPatternDetail,
} from "../../services/PermissionService";
import { useGetPermissionsPatternList } from "../../hooks/useGetPermissionsPatternList";
import { useGetLicensorList } from "../../hooks/useGetLicensorList";
import { useGetPermissionOptions } from "../../hooks/useGetPermissionOptions";
import { useGetPermissionsPattern } from "../../hooks/useGetPermissionPattern";
import { useUserInfo } from "../../provider/UserProvider";
import { useLicenseGroupCopyrights } from "../../hooks/useLicenseGroupCopyrights";

const Permission = forwardRef(
  (
    {
      selectedMedia,
      setAlertList,
      showSensitiveFields,
      permissionData,
      getPermission,
      selectedLanguageId,
      selectedBibleId,
      licenseGroups,
    }: PermissionProps,
    ref,
  ) => {
    const {
      createPermissionPattern,
      editPermissionPattern,
      applyPermissionPattern,
      updateLicensor,
      associateLicensor: associateLicensorService,
      disassociateLicensor,
      updateLicenseGroup,
    } = usePermissionService();
    const [permission, setPermission] = useState<PermissionInterface>();
    const [editingSelectedPattern, setEditingSelectedPattern] =
      useState<boolean>(false);
    const [confirmationDialog, setConfirmationDialog] =
      useState<ConfirmationDialog>({
        open: false,
        title: "Confirm",
        text: "Are you sure?",
        confirmCallback: () => {
          return;
        },
        cancelCallback: () => {
          return;
        },
      });
    const [showManageLicensorsDialog, setShowManageLicensorsDialog] =
      useState<boolean>(false);
    const [editingLicensor, setEditingLicensor] = useState<number>();
    const [selectedLicensor, setSelectedLicensor] = useState<number>();
    const stateRef = useRef<number>();
    stateRef.current = selectedLicensor;

    const [creatingPattern, setCreatingPattern] = useState<boolean>(false);
    const [editingPattern, setEditingPattern] = useState<boolean>(false);
    const [selectedPattern, setSelectedPattern] = useState<PermissionPattern>(
      {} as PermissionPattern,
    );
    const [newPattern, setNewPattern] = useState<NewPermissionPattern>();
    const { canAdminPermissions } = useUserInfo();

    const [customLicensePermission, setCustomLicensePermission] =
      useState<PermissionInterface>();
    const [isCustomizeEnable, setIsCustomizeEnable] = useState<boolean>(false);
    const [isPermissionSaving, setIsPermissionSaving] =
      useState<boolean>(false);
    const [isLicensorUpdating, setIsLicensorUpdating] =
      useState<boolean>(false);
    const [isInitialized, setIsInitialized] = useState(false);

    const editMode = {
      ASSOCIATE: "associate",
    };

    const { data: permissionOptionsData, refetch: getPermissionOptions } =
      useGetPermissionOptions();

    const { data: permissionPatternList, refetch: getPermissionsPatternList } =
      useGetPermissionsPatternList();

    const selectedPatternId = selectedPattern?.id;
    const permissionDataPatternId = permissionData?.permissionPattern?.id;

    // Determine if both patterns are the same
    const arePatternsSame = useMemo(() => {
      return selectedPatternId === permissionDataPatternId;
    }, [selectedPatternId, permissionDataPatternId]);

    // Fetch permissionPatternDetail
    const {
      data: permissionPatternDetail,
      isFetching: isLoadingPatternDetail,
      isError: isErrorPatternDetail,
      error: errorPatternDetail,
      refetch: getPermissionPattern,
    } = useGetPermissionsPattern(selectedPatternId);

    // Fetch permissionDataDetail
    const {
      data: permissionDataDetail,
      isFetching: isLoadingDataDetail,
      isError: isErrorDataDetail,
      error: errorDataDetail,
      refetch: getPermissionDataDetail,
    } = useGetPermissionsPattern(
      arePatternsSame ? selectedPatternId : (permissionDataPatternId ?? 0),
    );

    const {
      data: licenseGroupCopyrights,
      isLoading: isLoadingLGroupCopyrights,
      isError: isErrorLGroupCopyrights,
      error: errorLGroupCopyrights,
    } = useLicenseGroupCopyrights(licenseGroups, permission);

    useEffect(() => {
      if (isErrorDataDetail) {
        setAlertList((prev: AlertMessage[]) => {
          return [
            ...prev,
            {
              message: errorDataDetail?.message ?? "",
              key: new Date().getTime(),
              type: "error",
            },
          ];
        });
      }
    }, [isErrorDataDetail, errorDataDetail, setAlertList]);

    useEffect(() => {
      if (isErrorPatternDetail) {
        setAlertList((prev: AlertMessage[]) => {
          return [
            ...prev,
            {
              message: errorPatternDetail?.message ?? "",
              key: new Date().getTime(),
              type: "error",
            },
          ];
        });
      }
    }, [isErrorPatternDetail, errorPatternDetail, setAlertList]);

    useEffect(() => {
      if (isErrorLGroupCopyrights) {
        setAlertList((prev: AlertMessage[]) => {
          return [
            ...prev,
            {
              message: errorLGroupCopyrights?.message ?? "",
              key: new Date().getTime(),
              type: "error",
            },
          ];
        });
      }
    }, [isErrorLGroupCopyrights, errorLGroupCopyrights, setAlertList]);

    const { data: licensorList, refetch: getLicensorList } =
      useGetLicensorList();

    useEffect(() => {
      if (permissionData?.permissionPattern?.id) {
        getPermissionDataDetail();
      }
    }, [permissionData?.permissionPattern?.id, getPermissionDataDetail]);

    useEffect(() => {
      getPermissionOptions();
      getPermissionsPatternList();
      getLicensorList();
    }, [getPermissionOptions, getPermissionsPatternList, getLicensorList]);

    useEffect(() => {
      if (selectedPattern?.id) {
        getPermissionPattern();
      }
    }, [selectedPattern, getPermissionPattern]);

    useEffect(() => {
      if (selectedMedia?.licenseGroupId) {
        setEditingSelectedPattern(false);
      } else {
        setPermission(undefined);
      }
      setIsCustomizeEnable(false);
    }, [selectedMedia, getPermissionsPatternList]);

    useEffect(() => {
      if (permissionData) {
        setPermission(permissionData);
        const patternListMatch = permissionPatternList?.find(
          (element) => element.id === permissionData.permissionPattern.id,
        );
        setSelectedPattern(patternListMatch ?? ({} as PermissionPattern));
      } else {
        setSelectedPattern({} as PermissionPattern);
      }
    }, [permissionData, permissionPatternList]);

    useEffect(() => {
      const conditions = [
        selectedLanguageId,
        selectedMedia?.id,
        selectedBibleId,
      ];
      // If all conditions are undefined, it should not reset the permission pattern component because CRUD is enabled.
      // If at least one condition is undefined and at least one condition is defined, it should reset the permission pattern component.
      if (
        !conditions.every((condition: string | undefined) => !condition) &&
        conditions.some((condition: string | undefined) => !condition)
      ) {
        setSelectedPattern({} as PermissionPattern);
      }
    }, [selectedLanguageId, selectedMedia, selectedBibleId]);

    useEffect(() => {
      if (
        !isInitialized &&
        permissionData?.accessGroups &&
        selectedMedia?.licenseGroupId
      ) {
        setCustomLicensePermission({
          ...deepPermissionCopy(permissionData),
          id: selectedMedia.licenseGroupId,
        });
        setIsInitialized(true);
      }
    }, [permissionData, selectedMedia, isInitialized]);

    useImperativeHandle(ref, () => {
      return {
        refreshPermission() {
          getPermission();
        },
      };
    }, [getPermission]);

    const associateLicensor = () => {
      setPermission((prevState) => {
        if (!prevState) {
          return undefined;
        }

        const permissionCopy = { ...prevState };
        if (permissionCopy) {
          if (!permissionCopy.licensors) {
            permissionCopy.licensors = [];
          }

          permissionCopy.licensors?.push({
            id: 0,
            name: "",
            logo: "",
            editMode: editMode.ASSOCIATE,
          });
        }

        return permissionCopy;
      });
      setEditingLicensor(
        permission?.licensors ? permission.licensors.length - 1 : 0,
      );
    };

    const manageLicensors = () => {
      setShowManageLicensorsDialog(true);
    };

    const handleManageLicensorsDialogClose = () => {
      setShowManageLicensorsDialog(false);
      if (permission) {
        getPermission();
      }
      getLicensorList();
    };

    const handleDisassociateLicensor = (licensorId: number) => {
      const dialogDetails = {
        open: true,
        title: "Confirm Remove",
        text: "Are you sure you want to disassociate this licensor from this license agreement?",
        confirmCallback: handleConfirmDeleteLicensor,
        cancelCallback: handleCancelConfirmation,
      };
      setConfirmationDialog(dialogDetails);
      setSelectedLicensor(licensorId);
    };

    const handleConfirmDeleteLicensor = () => {
      const dialogDetails = {
        open: false,
        title: "Confirm",
        text: "Are you sure?",
        confirmCallback: () => {
          return;
        },
        cancelCallback: () => {
          return;
        },
      };
      setConfirmationDialog(dialogDetails);
      setIsLicensorUpdating(true);
      if (permission && stateRef.current) {
        disassociateLicensor(permission.id, stateRef.current)
          .then(() => {
            setSelectedLicensor(undefined);
            getPermission();
            setIsLicensorUpdating(false);
          })
          .catch((error: unknown) => {
            if (isAxiosError(error)) {
              if (error.response?.data?.error) {
                showMessage(error.response.data.error);
              } else {
                console.error("Error message:", error.message);
              }
            }
            setIsLicensorUpdating(false);
          });
      }
    };

    const handleCancelConfirmation = () => {
      const dialogDetails = {
        open: false,
        title: "Confirm",
        text: "Are you sure?",
        confirmCallback: () => {
          return;
        },
        cancelCallback: () => {
          return;
        },
      };
      setConfirmationDialog(dialogDetails);
    };

    const handlePermissionPatternChange = (value: PermissionPattern | null) => {
      setEditingSelectedPattern(true);

      if (permission) {
        setPermission((prevState) => {
          if (!prevState) {
            return undefined;
          }

          const permissionCopy = { ...prevState };
          if (permissionCopy && value) {
            permissionCopy.permissionPattern = value;
          }
          return permissionCopy;
        });
      }

      if (value) {
        setSelectedPattern(value);
      }
    };

    const handleLicensorInputChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      changedIndex: number,
      value: Licensor | null,
    ) => {
      setEditingLicensor(changedIndex);

      setPermission((prevState) => {
        if (!prevState) {
          // Handle the case where prevState is undefined
          return undefined;
        }

        const permissionCopy = { ...prevState };
        if (permissionCopy?.licensors) {
          permissionCopy.licensors.forEach(
            (licensor: Licensor, index: number) => {
              if (changedIndex === index) {
                if (value?.id) {
                  licensor.id = value.id;
                  licensor.name = value.name;
                  if (value.logo) {
                    licensor.logo = value.logo;
                  }
                } else if (event.target && event.target.name === "name") {
                  licensor.name = event.target.value;
                } else if (
                  event.target &&
                  event.target.name === "logo" &&
                  licensor.logo
                ) {
                  licensor.logo = event.target.value;
                }
              }
            },
          );
        }

        return permissionCopy;
      });
    };

    const saveLicensorEdits = (index: number) => {
      if (permission) {
        let serviceCall;
        const messages = {
          success: "",
          error: "",
        };
        setIsLicensorUpdating(true);
        if (permission.licensors[index].editMode === editMode.ASSOCIATE) {
          serviceCall = associateLicensorService(
            permission.id,
            permission.licensors[index],
          );
          messages.success = "Licensor associated to license group.";
          messages.error = "Error associating licensor to license group.";
        } else {
          serviceCall = updateLicensor(permission.licensors[index]);
          messages.success = "Licensor updated.";
          messages.error = "Error updating licensor.";
        }
        serviceCall
          .then(() => {
            showMessage(messages.success, "success");
            setEditingLicensor(undefined);
            getPermission();
            getLicensorList();
            setIsLicensorUpdating(false);
          })
          .catch((error: unknown) => {
            showMessage(messages.error);
            console.log(error);
            setIsLicensorUpdating(false);
          });
      }
    };

    const cancelLicensorEdits = () => {
      getPermission();
      setEditingLicensor(undefined);
    };

    const cancelEdits = () => {
      if (selectedMedia?.licenseGroupId) {
        getPermission().then(() => {
          if (permissionData) {
            setPermission(permissionData);
            setSelectedPattern(permissionData.permissionPattern);
            getPermissionPattern();
          }
        });
      }
      setEditingSelectedPattern(false);
      setCreatingPattern(false);
      setEditingPattern(false);
    };

    const permissionPatternMode = () => {
      if (permission && !creatingPattern) {
        return 1; //Mode 1: assign a permission pattern to a license group
      } else {
        return 2; //Mode 2: CRUD a permission Permission Pattern
      }
    };

    const applyPattern = () => {
      if (permission) {
        setIsPermissionSaving(true);
        applyPermissionPattern(permission)
          .then(() => {
            setIsPermissionSaving(false);
            showMessage("Pattern saved.", "success");
            setEditingSelectedPattern(false);
            getPermission();
          })
          .catch((error: unknown) => {
            setIsPermissionSaving(false);
            showMessage("Error saving pattern.");
            console.log(error);
          });
      }
    };

    const saveCustomLicenseGroupPermissions = () => {
      if (customLicensePermission) {
        setIsPermissionSaving(true);
        updateLicenseGroup(customLicensePermission)
          .then(() => {
            setIsPermissionSaving(false);
            showMessage("Custom Permissions have been saved.", "success");
            setIsCustomizeEnable(false);
            getPermission();
          })
          .catch((error: unknown) => {
            setIsPermissionSaving(false);
            setIsCustomizeEnable(false);
            showMessage("Error saving Custom Permissions.");
            console.log(error);
          });
      }
    };

    const cancelCustomLicenseGroupPermissions = () => {
      setIsCustomizeEnable(false);
    };

    const customizeLicenseGroup = () => {
      if (selectedMedia.licenseGroupId) {
        setIsCustomizeEnable(true);

        if (permissionData) {
          setCustomLicensePermission({
            ...deepPermissionCopy(permissionData),
            id: selectedMedia.licenseGroupId,
          });
        }
      }
    };

    const permissionOptionsDataIndexed: PermissionOption[] = [];
    permissionOptionsData?.forEach((permission: PermissionOption) => {
      permissionOptionsDataIndexed[permission.id] = permission;
    });

    const createPattern = () => {
      setCreatingPattern(true);
      const permissionOptions: PermissionOption[] = [];

      permissionPatternDetail?.permissionOptions?.forEach(
        (permission: PermissionOption) => {
          if (permissionOptionsDataIndexed[permission.id]) {
            permissionOptions.push({
              id: permission.id,
              modeId: permission.modeId,
              name: permissionOptionsDataIndexed[permission.id].name,
              description:
                permissionOptionsDataIndexed[permission.id].description,
              displayOrder:
                permissionOptionsDataIndexed[permission.id].displayOrder,
            });
          }
        },
      );
      setNewPattern({
        name: "",
        permissionOptions: permissionOptions,
      });
    };

    const editPattern = () => {
      setEditingPattern(true);
      const permissionOptions: PermissionOption[] = [];
      if (permissionOptionsData && permissionPatternDetail?.permissionOptions) {
        permissionPatternDetail?.permissionOptions.forEach(
          (permission: PermissionOption) => {
            if (permissionOptionsDataIndexed[permission.id]) {
              permissionOptions.push({
                id: permission.id,
                modeId: permission.modeId,
                name: permissionOptionsDataIndexed[permission.id].name,
                description:
                  permissionOptionsDataIndexed[permission.id].description,
                displayOrder:
                  permissionOptionsDataIndexed[permission.id].displayOrder,
              });
            }
          },
        );
      }
      setNewPattern({
        id: selectedPattern.id,
        name: selectedPattern.name || "",
        editedOriginalName: selectedPattern.name || "",
        permissionOptions: permissionOptions,
      });
    };

    const mapPermissionOption = (
      permissionId: number,
      modeId: number,
      checkedPermissionList: PermissionOption[] = [],
    ) => {
      return !!checkedPermissionList.find(
        (permission) =>
          permission.id === permissionId && permission.modeId === modeId,
      );
    };

    const updateCheckbox = useCallback(
      (modeId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const permissionOptionId = parseInt(event.target.name);

        setNewPattern((prevState) => {
          if (!prevState) {
            return undefined;
          }

          const patternCopy = { ...prevState };

          if (
            mapPermissionOption(
              permissionOptionId,
              modeId,
              patternCopy.permissionOptions,
            )
          ) {
            //remove the option from the list (deselect)
            const matchIndex = patternCopy.permissionOptions.findIndex(
              (permissionOption: PermissionOption) =>
                permissionOption.id === permissionOptionId &&
                modeId === permissionOption.modeId,
            );
            patternCopy.permissionOptions.splice(matchIndex, 1);
          } else {
            //add the option to the list (select)
            if (permissionOptionsData) {
              const matchIndex = permissionOptionsData.findIndex(
                (permissionOption: PermissionOption) =>
                  permissionOption.id === permissionOptionId,
              );
              patternCopy.permissionOptions.push({
                ...permissionOptionsData[matchIndex],
                modeId: modeId,
              });
            }
          }
          return patternCopy;
        });
      },
      [permissionOptionsData],
    );

    const updateCustomCheckbox = useCallback(
      (modeId: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const permissionOptionId = parseInt(event.target.name);
        setCustomLicensePermission((prevState) => {
          if (!prevState) {
            return undefined;
          }

          const prevLicensePermission = { ...prevState };

          if (
            mapPermissionOption(
              permissionOptionId,
              modeId,
              prevLicensePermission.accessGroups,
            )
          ) {
            //remove the option from the list (deselect)
            const matchIndex = prevLicensePermission.accessGroups.findIndex(
              (permissionOption: PermissionOption) =>
                permissionOption.id === permissionOptionId &&
                modeId === permissionOption.modeId,
            );
            prevLicensePermission.accessGroups.splice(matchIndex, 1);
          } else {
            //add the option to the list (select)
            if (permissionOptionsData) {
              const matchIndex = permissionOptionsData.findIndex(
                (permissionOption: PermissionOption) =>
                  permissionOption.id === permissionOptionId,
              );
              prevLicensePermission.accessGroups.push({
                ...permissionOptionsData[matchIndex],
              });
            }
          }
          return prevLicensePermission;
        });
      },
      [permissionOptionsData],
    );

    const handlePatternNameChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      setNewPattern((prevState) => {
        if (!prevState) {
          return undefined;
        }

        const newPatternCopy = { ...prevState };
        if (event.target.name === "name") {
          newPatternCopy.name = event.target.value;
        } else {
          newPatternCopy.editedOriginalName = event.target.value;
        }
        return newPatternCopy;
      });
    };

    const savePattern = () => {
      if (newPattern) {
        let serviceCall;
        if (creatingPattern) {
          if (!newPattern.name) {
            showMessage("Pattern name is required.");
            return;
          }

          serviceCall = createPermissionPattern(newPattern);
        } else {
          if (!newPattern.editedOriginalName) {
            showMessage("Edited name is required.");
            return;
          }

          serviceCall = editPermissionPattern(newPattern);
        }
        serviceCall
          .then((updatedPatternDetail: PermissionPatternDetail) => {
            showMessage("Pattern saved.", "success");
            setEditingSelectedPattern(false);
            setCreatingPattern(false);
            setEditingPattern(false);
            getPermissionsPatternList().then((updatedPatternList) => {
              // select the edited/created pattern
              const patternListMatch = updatedPatternList?.data?.find(
                (element: PermissionPattern) =>
                  element.id === updatedPatternDetail.id,
              );
              if (patternListMatch) {
                setSelectedPattern(patternListMatch);
              }
            });
            if (selectedPattern.id) {
              getPermissionPattern();
            }

            if (selectedMedia?.licenseGroupId) {
              getPermission();
            }
          })
          .catch((error: unknown) => {
            if (isAxiosError(error)) {
              if (error.response?.data?.error) {
                showMessage(error.response.data.error);
              } else {
                console.error("Error message:", error.message);
              }
            }
          });
      }
    };

    const handleEditPatternSave = () => {
      if (selectedPattern?.associatedMediaRecords > 0) {
        const dialogDetails = {
          open: true,
          title: "Confirm Edit",
          text: `Are you sure you want to save this change? This will affect ${selectedPattern.associatedMediaRecords} media records associated with this license agreement.`,
          confirmCallback: handleConfirmEditPattern,
          cancelCallback: handleCancelConfirmation,
        };
        setConfirmationDialog(dialogDetails);
      } else {
        handleConfirmEditPattern();
      }
    };

    const handleConfirmEditPattern = () => {
      const dialogDetails = {
        open: false,
        title: "Confirm",
        text: "Are you sure?",
        confirmCallback: () => {
          return;
        },
        cancelCallback: () => {
          return;
        },
      };
      setConfirmationDialog(dialogDetails);

      savePattern();
    };

    const showMessage = (message: string, type = "error") => {
      setAlertList((prev: AlertMessage[]) => {
        return [
          ...prev,
          {
            message: message,
            key: new Date().getTime(),
            type: type as "error" | "success" | "warning",
          },
        ];
      });
    };

    const hasCustomLicensePermission =
      selectedMedia?.licenseGroupId &&
      permissionDataDetail?.permissionOptions &&
      permissionDataDetail?.permissionOptions.length > 0 &&
      permissionData?.accessGroups &&
      permissionData?.accessGroups.length > 0 &&
      permissionDataDetail?.id === permissionData?.permissionPattern?.id &&
      !arePermissionsEqualById(
        permissionDataDetail?.permissionOptions,
        permissionData?.accessGroups,
      );

    const getPermissionPatternValue = (
      selectedPatternInput: PermissionPattern,
    ): PermissionPattern | null => {
      if (hasCustomLicensePermission && !editingSelectedPattern) {
        return {
          id: PERMISSION_CUSTOM_ID,
          name: PERMISSION_CUSTOM_NAME,
          associatedMediaRecords: 0,
        };
      }
      return selectedPatternInput?.id ? selectedPatternInput : null;
    };

    return (
      <>
        <Backdrop
          sx={styles.backdrop}
          open={
            isPermissionSaving ||
            isLicensorUpdating ||
            isLoadingLGroupCopyrights ||
            isLoadingPatternDetail ||
            isLoadingDataDetail
          }
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid
          container
          justifyContent="space-between"
          alignItems="baseline"
          style={{ gap: "10px" }}
        >
          <Grid item xs={6}>
            <Typography variant="h3" sx={styles.sectionTitle}>
              License Agreement
            </Typography>
          </Grid>
        </Grid>
        <Dialog open={confirmationDialog.open}>
          <DialogTitle>{confirmationDialog.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{confirmationDialog.text}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={confirmationDialog.confirmCallback}
              color="primary"
            >
              Confirm
            </Button>
            <Button
              onClick={confirmationDialog.cancelCallback}
              color="secondary"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container>
          <Grid item xs={12} sx={{ marginBottom: "20px" }}>
            <TextField
              label="License Group"
              value={permission?.name ?? ""}
              name="name"
              variant="outlined"
              disabled
              sx={{ width: "600px" }}
            />
          </Grid>
          {showSensitiveFields() && (
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="baseline"
              >
                <Grid item xs={9}>
                  <Typography variant="h6" sx={styles.subSectionTitle}>
                    Licensors
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={styles.alignRight}>
                  {canAdminPermissions && selectedMedia.id && (
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={associateLicensor}
                      sx={{ marginRight: "10px" }}
                    >
                      Associate Licensor
                    </Button>
                  )}
                  {canAdminPermissions && (
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={manageLicensors}
                    >
                      Manage Licensors
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          {showSensitiveFields() && (
            <Grid item xs={12}>
              <TableContainer component={Paper} sx={styles.licensorTable}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Logo</TableCell>
                      <TableCell>{/* controls */}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {permission?.licensors?.map(
                      (licensor: Licensor, index: number) => (
                        <TableRow key={index}>
                          <TableCell sx={styles.rightBorder}>
                            {licensor.editMode === editMode.ASSOCIATE && (
                              <Autocomplete
                                autoHighlight
                                onChange={(_, value) =>
                                  handleLicensorInputChange(
                                    {} as ChangeEvent<HTMLInputElement>,
                                    index,
                                    value,
                                  )
                                }
                                value={licensor?.id ? licensor : null}
                                getOptionLabel={(option) => option.name || ""}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                                options={licensorList ?? []}
                                sx={{ width: "100%" }}
                                size="small"
                                fullWidth
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                disabled={!canAdminPermissions}
                              />
                            )}
                            {licensor.editMode !== editMode.ASSOCIATE &&
                              licensor.name}
                          </TableCell>
                          <TableCell sx={[styles.rightBorder]}>
                            {licensor.logo && (
                              <img
                                alt="Licensor Logo"
                                height={"100px"}
                                src={licensor.logo}
                              />
                            )}
                          </TableCell>
                          <TableCell
                            sx={[styles.alignCenter, styles.controlCellWidth]}
                          >
                            {canAdminPermissions &&
                            editingLicensor === index ? (
                              <Stack direction="row" spacing={0}>
                                <Tooltip title="Save Changes">
                                  <IconButton
                                    onClick={() => saveLicensorEdits(index)}
                                  >
                                    <Check color="success" fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Cancel Changes">
                                  <IconButton onClick={cancelLicensorEdits}>
                                    <Clear color="primary" fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            ) : (
                              canAdminPermissions && (
                                <Tooltip title="Disassociate Licensor">
                                  <IconButton
                                    onClick={() =>
                                      handleDisassociateLicensor(licensor.id)
                                    }
                                  >
                                    <LinkOff color="primary" fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              )
                            )}
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          {showSensitiveFields() && (
            <CopyrightEditor
              permission={permission}
              mediaModeId={selectedMedia.mediaModeType}
              disabled={!selectedMedia.id || !canAdminPermissions}
              setPermission={setPermission}
              showMessage={showMessage}
              getPermission={getPermission}
              licenseGroupCopyrights={licenseGroupCopyrights}
            />
          )}
          {permissionPatternMode() === 2 && (
            <Divider sx={{ width: "100%", borderBottomWidth: "medium" }} />
          )}
          <Grid item xs={12} sx={{ marginBottom: "20px" }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" sx={styles.patternTitle}>
                  {permissionPatternMode() === 1 &&
                    "License Group Permissions Pattern"}
                  {permissionPatternMode() === 2 &&
                    "Permissions Pattern Editor"}
                </Typography>
                {permission && editingSelectedPattern && (
                  <Typography variant="subtitle2" sx={styles.patternSubTitle}>
                    Saving the selected permissions pattern will associate it
                    with the {permission?.name} license group.
                  </Typography>
                )}
                {permission && creatingPattern && (
                  <Typography variant="subtitle2" sx={styles.patternSubTitle}>
                    This new pattern will not automatically be associated with
                    the {permission?.name} license group. After creating the new
                    pattern, to associate it with the license group, select it
                    from the dropdown and save.
                  </Typography>
                )}
                {permission && editingPattern && (
                  <Typography variant="subtitle2" sx={styles.patternSubTitle}>
                    Editing this pattern will affect the {permission?.name}{" "}
                    license group and all other license groups associated with
                    this permission pattern.
                  </Typography>
                )}
                {!permission && creatingPattern && (
                  <Typography variant="subtitle2" sx={styles.patternSubTitle}>
                    This new pattern will not automatically be associated with
                    any license group, but will be available in the permissions
                    pattern dropdown when viewing a license group.
                  </Typography>
                )}
                {!permission && editingPattern && (
                  <Typography variant="subtitle2" sx={styles.patternSubTitle}>
                    Editing this pattern will affect any license group using
                    this permissions pattern. If you change the name, the new
                    name will be associated with the current pattern
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  <Autocomplete
                    autoHighlight
                    onChange={(event, value) =>
                      handlePermissionPatternChange(value)
                    }
                    value={getPermissionPatternValue(selectedPattern)}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    options={permissionPatternList ?? []}
                    getOptionDisabled={(selectedPatternOption) =>
                      selectedPatternOption.id === PERMISSION_CUSTOM_ID
                    }
                    sx={{ minWidth: "300px" }}
                    renderInput={(params) => <TextField {...params} />}
                    disabled={
                      creatingPattern ||
                      editingPattern ||
                      !canAdminPermissions ||
                      isCustomizeEnable
                    }
                  />
                  {canAdminPermissions &&
                    permission &&
                    editingSelectedPattern &&
                    !creatingPattern &&
                    !editingPattern && (
                      <Stack direction="row" spacing={0}>
                        <Tooltip title="Save Changes">
                          <IconButton onClick={applyPattern}>
                            <Check color="success" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel Changes">
                          <IconButton onClick={cancelEdits}>
                            <Clear color="primary" fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    )}
                  {!(
                    creatingPattern ||
                    editingPattern ||
                    (permission && editingSelectedPattern)
                  ) && canAdminPermissions ? (
                    <Button
                      color="secondary"
                      onClick={createPattern}
                      variant="outlined"
                      disabled={
                        isCustomizeEnable || !!hasCustomLicensePermission
                      }
                    >
                      Create Pattern
                    </Button>
                  ) : null}
                  {!(
                    creatingPattern ??
                    editingPattern ??
                    (permission && editingSelectedPattern)
                  ) &&
                  selectedPattern?.id &&
                  canAdminPermissions ? (
                    <Button
                      color="secondary"
                      onClick={editPattern}
                      variant="outlined"
                      disabled={
                        isCustomizeEnable || !!hasCustomLicensePermission
                      }
                    >
                      Edit Pattern
                    </Button>
                  ) : null}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  {(creatingPattern || editingPattern) &&
                    canAdminPermissions && (
                      <TextField
                        label={
                          creatingPattern ? "Pattern Name" : "Original Name"
                        }
                        autoFocus
                        value={newPattern?.name ?? ""}
                        onChange={handlePatternNameChange}
                        disabled={editingPattern}
                        name="name"
                        variant="outlined"
                        sx={{ width: "300px" }}
                      />
                    )}
                  {editingPattern && canAdminPermissions && (
                    <TextField
                      label="Edited Name"
                      autoFocus
                      value={newPattern?.editedOriginalName ?? ""}
                      onChange={handlePatternNameChange}
                      name="editedOriginalName"
                      variant="outlined"
                      sx={{ width: "300px" }}
                    />
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                <Typography variant="h6" sx={styles.subSectionTitle}>
                  Permissions {hasCustomLicensePermission ? "custom" : ""}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {selectedMedia.licenseGroupId &&
                !editingSelectedPattern &&
                !(creatingPattern || editingPattern) &&
                canAdminPermissions &&
                permissionData?.permissionPattern.id ? (
                  <Button
                    color="secondary"
                    onClick={customizeLicenseGroup}
                    variant="outlined"
                    sx={styles.subSectionTitle}
                  >
                    Customize License Group
                  </Button>
                ) : (
                  ""
                )}
                {isCustomizeEnable && !editingSelectedPattern && (
                  <>
                    <Tooltip title="Save Changes">
                      <IconButton
                        onClick={saveCustomLicenseGroupPermissions}
                        size="large"
                      >
                        <Check color="success" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancel Changes">
                      <IconButton
                        onClick={cancelCustomLicenseGroupPermissions}
                        size="large"
                      >
                        <Clear color="primary" />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PermissionTableContainer
              isCustomizeEnable={isCustomizeEnable}
              hasCustomLicensePermission={!!hasCustomLicensePermission}
              creatingPattern={creatingPattern}
              editingPattern={editingPattern}
              permissionOptionsData={permissionOptionsData ?? []}
              permissionPatternDetail={permissionPatternDetail ?? {}}
              newPattern={newPattern}
              selectedMedia={selectedMedia}
              customLicensePermission={customLicensePermission}
              permissionData={permissionData}
              updateCheckbox={updateCheckbox}
              updateCustomCheckbox={updateCustomCheckbox}
              editingSelectedPattern={editingSelectedPattern}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "right",
              gap: "10px",
              padding: "20px 0",
            }}
          >
            {(creatingPattern || editingPattern) && (
              <Stack direction="row" spacing={0}>
                <Tooltip title="Save Changes">
                  <IconButton
                    onClick={
                      creatingPattern ? savePattern : handleEditPatternSave
                    }
                    size="large"
                  >
                    <Check color="success" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel Changes">
                  <IconButton onClick={cancelEdits} size="large">
                    <Clear color="primary" />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
          </Grid>
        </Grid>
        <ManageLicensorsDialog
          open={showManageLicensorsDialog}
          onClose={handleManageLicensorsDialogClose}
          setAlertList={setAlertList}
          licensorList={licensorList ?? []}
        />
      </>
    );
  },
);

export default React.memo(Permission);
